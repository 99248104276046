import * as React from "react"
import { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import InfiniteScroll from "react-infinite-scroll-component"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import EventCard from "../components/EventCard"
import SignUpSection from "../components/SignUpSection"

const Events = ({ data }) => {
  const currentDate = new Date()
  const currentDateString = currentDate.toISOString().split('T')[0];

  let eventsData = data.events.nodes
    .filter(event => {
      const eventDateString = event.frontmatter.event_date.split('T')[0];
      return eventDateString >= currentDateString;
    })
    .sort(
      (a, b) =>
        new Date(a.frontmatter.event_date) - new Date(b.frontmatter.event_date)
    );

  const [events, setEvents] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)

  /* REMOVE AFTER TESTING */
  // eventsData.push(...eventsData);
  // eventsData.push(...eventsData);
  // eventsData.push(...eventsData);
  
  useEffect(() => {
    fetchMoreEvents()
  }, [])

  const fetchMoreEvents = () => {
    // Simulate fetching data from an API or GraphQL query
    const newEvents = eventsData.slice((page - 1) * 9, page * 9)
    setEvents([...events, ...newEvents])
    setPage(page + 1)

    if (newEvents.length === 0) {
      setHasMore(false)
    }
  }

  return (
    <>
      <Seo title="Events" />

      <Hero headline="Events" page="events">
        <StaticImage
          className="hero__picture"
          src="../../static/media/about-hero.jpg"
          alt=""
          loading="eager"
        />
      </Hero>

      <div className="page-content page-content--cards events">
        <InfiniteScroll
          dataLength={events.length}
          next={fetchMoreEvents}
          hasMore={hasMore}
          loader={ events.length >= 9 ? <h4 className="more-events text-center">Loading more events...</h4> : '' }
          endMessage={ events.length > 9 ?<h4 className="text-center">No more events</h4>: ''}
          scrollThreshold="200px"
        >
          <div className="container">
            <div className="row">
              {events.map(event => (
                <div className="col-md-4">
                  <EventCard
                    key={event.id}
                    eventName={event.frontmatter.event_name}
                    eventDate={event.frontmatter.event_date}
                    eventTimeFrom={event.frontmatter.event_time_from}
                    eventTimeTo={event.frontmatter.event_time_to}
                    eventPlace={event.frontmatter.event_place}
                    eventSlug={event.frontmatter.event_slug}
                    eventId={event.id}
                  />
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>

      <SignUpSection />
    </>
  )
}

// = = = = = = = =
// GraphQL
export const data = graphql`
  query EventsQuery {
    events: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "event" } } }
    ) {
      nodes {
        frontmatter {
          event_name
          event_slug
          event_date
          event_time_from
          event_time_to
          event_place
        }
        id
      }
    }
  }
`
export default Events
