import React from 'react';
import { Link } from 'gatsby';

function EventCard({ eventName, eventDate, eventTimeFrom, eventTimeTo, eventPlace, eventSlug, eventId }) {
return (
    <Link className="event-card" to={ `/event/${eventSlug}` }>
        <div className='event-card__date'>{new Date(eventDate).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}</div>
        <div className='event-card__content'>
            <h3>{eventName}</h3>
            <p>{eventTimeFrom} to {eventTimeTo}</p>
            <p>{eventPlace}</p>
        </div>
    </Link>
);
}

export default EventCard;